const devConfig = {
  backendUrl: process.env.REACT_APP_CORE_HOST || 'https://backoffice-service.dev.ivy-solutions.de',
  geocodingApiKey: process.env.REACT_APP_GEOCODING_API_KEY || 'AIzaSyArUXUObo0PywfpHfIQU9pw0wqkbU5BxOQ',
  cypress: process.env.REACT_APP_CYPRESS || false,
  sentryEnabled: false,
};

const prodConfig = {
  backendUrl: 'https://backoffice-service.ivy-solutions.de',
  geocodingApiKey: 'AIzaSyArUXUObo0PywfpHfIQU9pw0wqkbU5BxOQ',
  cypress: false,
  sentryEnabled: true,
};

export const config = process.env.REACT_APP_PROJECT_ID === 'ivy-production-73547' ? prodConfig : devConfig;
