import { useEffect, useState } from 'react';
import { components } from '../../gen/backoffice-service';
import { Operator } from '../model';
import { axiosDelete, get, post, put } from './requestUtils';
import { getAuthToken } from '../auth/auth';

type OperatorListPayload = components['schemas']['OperatorListPayload'];
export type PutEmailTemplateBody = components['schemas']['PutEmailTemplateBody'];
export type CompanyEmailTemplate = components['schemas']['CompanyEmailTemplate'];
export type CompanyEmailTemplateType = components['schemas']['CompanyEmailTemplateType'];

export const addOperator = async (data: { name: string; email: string; company: string }): Promise<Operator> => {
  const token = await getAuthToken();
  const response = await post<Operator>('/company/admin', data, token);
  return response.data;
};


export const deleteOperator = async (operatorId: string): Promise<Operator> => {
  const token = await getAuthToken();
  const response = await axiosDelete(`/admin/operator/${operatorId}`, token);
  return response.data;
};

export const useOperators = (): Operator[] | undefined => {
  const [data, setData] = useState<Operator[] | undefined>(undefined);
  useEffect(() => {
    getOperators().then(data => {
      setData(mapOperators(data));
    });
  }, []);
  return data;
};

export const getOperators = async (): Promise<OperatorListPayload> => {
  const token = await getAuthToken();
  const response = await get('/operators', token);
  return response.data;
};

export const mapOperators = (data: any): Operator[] | undefined => {
  return data.operators.map((operator: Operator) => {
    return {
      id: operator.id,
      name: operator.name,
      email: operator.email,
    };
  });
};

export const getCompanyEmailTemplates = async (): Promise<CompanyEmailTemplate[] | undefined> => {
  const token = await getAuthToken();

  if (token) {
    const response = await get('/company/email-templates', token);

    return response.data.data;
  }

  return;
};

export const upsertCompanyEmailTemplates = async (args: PutEmailTemplateBody[]): Promise<void> => {
  const token = await getAuthToken();

  if (token) {
    await put('/company/email-templates', args, token);
    return;
  }

  return;
};
