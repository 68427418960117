// --- external imports
import { Heartbeat } from 'phosphor-react';
import * as React from 'react';

// --- helpers
import { Link, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { colors } from '../../../theming/colors';

const ParkMonitoringDashboardItem: React.FC = () => {
  return (
    <div className={'e2e-go-to-park-monitoring-dashboard'}>
      <Link
        href="http://localhost:3001"
        color={colors.white}
        sx={{
          textDecoration: 'none',
          color: 'white',
          '&:visited': {
            color: 'white',
          },
        }}
      >
        <ListItem
          button
          sx={{
            marginY: 1.5,
            color: 'indigo',
          }}
        >
          <ListItemIcon sx={{ justifyContent: 'center' }}>
            <Heartbeat color={'white'} size={20} />
          </ListItemIcon>
          <ListItemText sx={{ color: 'white' }} primary={<b>Park Monitoring</b>} />
        </ListItem>
      </Link>
    </div>
  );
};

export default ParkMonitoringDashboardItem;
