import { useEffect, useState } from 'react';
import { components } from '../../gen/backoffice-service';
import { AdminUser, Company } from '../model';
import { get, post, put } from './requestUtils';
import { getAuthToken } from '../auth/auth';
import { UserRole } from '@ivy/proto/dist/users/v1/auth';

type AdminListPayload = components['schemas']['AdminListPayload'];

type AdminGetCompaniesPayload = components['schemas']['AdminGetCompaniesPayload'];
type AdminGetCompaniesCompanyPayload = components['schemas']['AdminGetCompaniesCompanyPayload'];
type AdminGetCompanyPayload = components['schemas']['AdminGetCompanyPayload'];

type PostCompanyBody = components['schemas']['AdminPostCompanyBody'];
type PutUserBody = components['schemas']['AdminPutUserBody'];

export const selectCompany = async (userId: string, body: PutUserBody): Promise<void> => {
  const token = await getAuthToken();
  await put(`/admin/user/${userId}`, body, token);
};

export const useCompanies = (): Company[] | undefined => {
  const [data, setData] = useState<AdminGetCompaniesCompanyPayload[] | undefined>(undefined);
  useEffect(() => {
    getCompanies().then(data => {
      setData(data.companies);
    });
  }, []);
  return data;
};

export const getCompanies = async (): Promise<AdminGetCompaniesPayload> => {
  const token = await getAuthToken();
  const response = await get(`/admin/companies`, token);
  return response.data;
};

export const getCompany = async (): Promise<AdminGetCompanyPayload> => {
  const token = await getAuthToken();
  const response = await get(`/admin/company`, token);
  return response.data;
};

export const useAdmins = (): AdminUser[] | undefined => {
  const [data, setData] = useState<AdminUser[] | undefined>(undefined);
  useEffect(() => {
    getAdmins().then(data => {
      setData(mapAdmins(data));
    });
  }, []);
  return data;
};

export const getAdmins = async (): Promise<AdminListPayload> => {
  const token = await getAuthToken();
  const response = await get(`/admins`, token);
  return response.data;
};

export const addCompany = async (name: string): Promise<Company> => {
  const body: PostCompanyBody = { name };
  const token = await getAuthToken();
  const response = await post(`/admin/companies`, body, token);
  return response.data;
};

export const addIvyAdmin = async (data: { name: string; email: string }): Promise<AdminUser> => {
  const token = await getAuthToken();
  const response = await post(
    `/admin/user`,
    {
      ...data,
      role: UserRole.USER_ROLE_ADMIN,
    },
    token,
  );
  return response.data;
};

export const mapAdmins = (data: any): AdminUser[] | undefined => {
  return data.admins.map((admin: AdminUser) => {
    return {
      id: admin.id,
      name: admin.name,
      email: admin.email,
    };
  });
};
